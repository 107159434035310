@import (less) './bootstrap-datepicker3.min.css';

body {
	background-color: #EBEBEB;

	.topbar {
		position: fixed;
		top:0px;
		left:0px;
		right:0px;
		width:100%;
		display:block;
		padding:15px 5px;
		text-align: center;
		background-color: fadeout(darken(@brand-primary, 10%), 15%);
		color: #ffffff;
		z-index:909090;
		.topbar__close {
			cursor: pointer !important;
			position:absolute;
			right:10px;
			display:block;
			height:100%;
			z-index:909091;
			color: #ffffff;
			top: 5px;
		}
		p {
			margin:0px;
			a {
				color: #fff;
				text-decoration: underline;
			}
		}
	}


	.eu-cookies {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		color: #ffffff;
		background-color: darken(@brand-primary, 10%);
		z-index: 1000;
		padding: 5px 10px;
		a {
			color: #fff;
			text-decoration: underline;
		}
	}

	.eu-cookies button {
		background: #505f50;
		border: 1px solid #505f50;
		color: white;
	}

	header {
		position:absolute;
		top:0px;
		left:0px;
		right:0px;
		overflow: hidden;
		padding-bottom:60px;
		.serviceBar {
			margin: 5px 0px;
			&__segments {
				list-style: none;
				margin:0px;
				padding:0px;
				li {
					display:inline-block;
					a {
						color: @gray-base;
						display:block;
						padding: 5px 10px;
					}
				}
			}
		}
		#logo {
			margin-top:30px;
			display:inline-block;
			img {
				position:relative;
				z-index:10;
			}
			&:before {
				content: "";
				position: absolute;
				top: -7px;
				left: -400px;
				background: url('../images/parts/header_@{brand}.png') no-repeat top left;
				width:1000px;
				z-index:5;
				height: 200px;
			}
		}
		#menu {
			background-color: @brand-primary;
			border-top-left-radius:12px;
			border-bottom-left-radius:12px;
			position: relative;
			ul {
				li {
					a {
						padding: 15px 25px;
						color: #fff;
						font-weight: bold;
						&:hover {
							background-color: darken(@brand-primary, 30%);
						}
					}
				}
			}
			@media (min-width: @screen-md-min)  {
				&:before {
					content: "";
					display:block;
					position: absolute;
					top:0px;
					width:100%;
					left: 100%;
					height: 50px;
					background: @brand-primary;
				}
			}
			@media (max-width: @screen-sm-max) {
				border-top-left-radius:0px;
				border-bottom-left-radius:0px;
			}
		}
		.userControl {
			position: relative;
			z-index: 20;
		}
	}

	.easyNavi {
		padding: 30px 0px;
		background-color: #f1f2f2;
		&__item {
			font-weight: bold;
			text-align: center;
			display:block;
			color: @gray-base;
			position:relative;
			&:hover {
				&__inner {
					h3 {
						color: #f1f2f2;
					}
				}
			}
			&__inner {
				position:absolute;
				z-index:10;
				top:0px;
				width:100%;
				height:100%;
				h3 {
					margin: 0px;
					padding: 0px;
					color: #f1f2f2;
					font-size: (@font-size-base + 2px);
					font-weight: 600;
				}
			}
			@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
				margin-bottom:15px;
			}
			@media (max-width: @screen-xs-max) { 
				margin-bottom:15px;
				&__inner {
					h3  {
						font-size: (@font-size-base - 4px);
					}
				}
			}
		}
	}


	.footer {
		background-color: #393939;
		padding:30px 0px;
		.footer-navigator {
			text-align: right;
			ul {
				list-style:none;
				margin:0px;
				padding:0px;
				display:inline-block;
				li {
					display: inline-block;
					margin:0px 10px;
					text-transform: uppercase;
					a {
						color: #fff;
					}
				}
			}
			&--socials {
				margin-bottom:20px;
				li {
					margin:0px 5px;
				}
			}
		}
		.footer-copyright {
			color: silver;
			p {
				margin:0px;
				padding:0px;
			}
		}
		@media (max-width: @screen-sm-max) { 
			text-align: center;
			.footer-navigator {
				text-align: center;
			}
		}
	}

	.cooperation {
		background-color: #ffffff;
		padding: 30px 0px;
		font-size:10px;
		color: #393939;
		&__block {
			margin-bottom:20px;
		}
	}

	.icon-ball {
		width: 100%;
		height: 100%;
		display:block;
		&__inner {
			text-align: center;
			vertical-align: middle;
			width:100%;
			display: block;
			.fa {
				font-size: 55px;
				height: 60px;
				color: #fff;
			}
		}
		&:hover {
			.fa {
				color: #f1f2f2;
			}
		}
		@media (max-width: @screen-xs-max) {
			.fa {
				font-size: 40px;
			}
		}
	}

	.vertical-center {
		&:before {
			content: "";
			display: inline-block;
			height: 100%;
			vertical-align: middle;
			margin-right: -.25em;
		}
		&__content {
			display:inline-block;
			vertical-align: middle;
		}
	}

	.nav-tabs__wrapper {
		border-bottom: 1px solid silver;
		text-align: center;
		z-index: 10;
		margin-top: 50px;
		@media (max-width: @screen-xs-max) { 
			margin-top: -15%;
		}
		.nav-tabs {
			margin-bottom: -5px;
			li {
				img {
					.opacity(0.5);
				}
				&.active {
					img {
						.opacity(1);
					}
				}
				&:not(.active) {
					a {
						cursor: pointer !important;
						&:hover {
							border:1px solid transparent;
							background-color: transparent;
						}
					}
				}
				@media (max-width: @screen-xs-max) { 
					img {
						max-width: 40px;
					}
				}
			}
			&-linking {
				li {
					a {
						cursor: pointer !important;
					}
				}
			}
		}
	}

	.serviceAbout {
		padding: 30px 0px;
		background-color: #fff;
		&__inner {
			p {
				font-size: (@font-size-base + 3px);
			}
			&__links {
				margin-top:25px;
				text-align: center;
			}
		}
	}

	.serviceAboutDetail {
		margin-bottom:100px;
		&__header {
			&__image {
				height:30vh;
				width:100%;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
			}
		}
		&__content {
			&__name {
				top:-100px;
				position: relative;
				display:block;
				height:200px;
				width:200px;
				img {
					border: 10px solid #fff;
				}
				h1 {
					display:block;
					width:100%;
					position: absolute;
					color: #fff;
					text-align: center;
					z-index: 50;
					top:30%;
					left:0px;
				}
			}
			&__inner {
				margin-top:-50px;
				padding:0px 50px;
				p {
					font-size: (@font-size-base + 2px);
					padding-bottom: 20px;
					text-align: justify;
				}
				&__links {
					margin-top: 50px;
					text-align: center;
				}
				@media (max-width: @screen-xs-max) { 
					padding: 0px 0px;
				}
			}
		}
	}

	.promoTextBox {
		background-color: @brand-primary;
		&__inner {
			text-align: center;
			padding:30px 15px;
			p {
				margin:0px;
				color: #fff;
				font-size: @font-size-h3;
				font-weight: bold;
			}
			a {
				color: #fff;
				font-size: @font-size-h2;
				&:hover {
					color: #fff;
					text-decoration: none;
				}
			}
		}
		&--silver {
			background-color: #5D5D5D;
		}
	}

	.serviceAboutConfiguration {
		padding-top:50px;
		//margin-bottom:100px;
		padding-bottom:100px;
		//border-bottom:20px solid @brand-primary;
		&__content {
			&__name {
				position: relative;
				display:block;
				height:200px;
				width:200px;
				img {
					border: 10px solid #fff;
				}
				h1 {
					display:block;
					width:100%;
					position: absolute;
					color: #fff;
					text-align: center;
					z-index: 50;
					top:30%;
					left:0px;
				}
			}
			&__inner {
				h2 {
					color: #b7b7b7;
					font-size: @font-size-h3;
				}
			}
		}
		@media (max-width: @screen-xs-max) { 
			text-align: center;
			&__content {
				&__name {
					margin:0px auto;
				}
			}
		}
	}

	.configurationForm {
		padding-top:50px;
		padding-bottom:50px;
		&__inner {
			&__item {
				margin-bottom:15px;
			}
		}
	}

	.content {
		text-align: center;
		position: relative;
		height: 90vh;
		.nav-tabs__wrapper {
			position: absolute;
			left:0px;
			right:0px;
			bottom:0px;
			margin-top:0px;
		}
		&__inner {
			padding: 10px;
			position: absolute;
			top: 50%;
			width:100%;
			transform: translateY(-50%);
			margin:0px auto;
			h1 {
				font-size: (@font-size-h1 + 20px);
				font-weight: 800;
				color: #848484;
				small {
					margin-top:5px;
					display:block;
					color: @brand-primary;
				}
			}
			a {
				margin-top:5px;
				color: #848484;
				font-size: 40px;
				display:block;
				&:hover {
					color: @brand-primary;
				}
			}
			@media (max-width: @screen-xs-max) {
				h1 { 
					font-size: @font-size-h1;
				}
			}
			@media (min-width: @screen-sm-min) and (max-width: 1500px) {
				transform: translateY(-90%);
				h1 { 
					font-size: (@font-size-h1 - 3px);
				}
			}
		}

		&--image {
			background-color: transparent;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
		}

		&--half {
			height:50vh;
		}

		@media (max-height: 645px)
		{
			height: 700px;
			&--half {
				height:350px;
			}
		}
	}

	.navbar-toggle {
		float: none;
		span {
			background-color: @brand-primary;
		}
	}

	.animation {
		&__boxes {
			> div:not(:last-child) {
				.animation__boxes__item .box {
					&:after {
						content: "";
						position: absolute;
						background-image:url('../images/icons/plus_@{brand}.png');
						width: 100px;	
						height: 100px;
						top: 50%;
						margin-top: -50px;
						right: -50px;
						background-repeat: no-repeat;
						background-position: center center;
						background-size: 100% 100%;
						@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
							width:80px;
							height:80px;
							right: -40px;
							margin-top: -40px;
						}
						@media (max-width: @screen-xs-max) { 
							display:none;
						}
					}
				}
			}
			&__item {
				.box {
					border-radius: 10em;
					border:10px solid #B8B8B8;
					border-radius:10px;
					padding:15px 15px;
					.animate {
						img {
							max-height:100px;
							height: 100%;
						}
						.text {
							height: 100px;
							width:100%;
							display:table;
							overflow:hidden;
							font-weight:bold;
							font-size: @font-size-h3;
							color: @brand-primary;
							text-align: center;
							&__inner {
								display:table-cell;
								vertical-align: middle;
								padding:0px !important;
							}
						}
					}
					@media (max-width: @screen-xs-max) { 
						margin-bottom:30px;
						.animate {
							img {
								max-height: 70px;
							}
							.text {
								height: 70px;
								font-size: @font-size-h5;
							}
						}
					}
				}
			}
			.item1 .animate{
				animation: littleBoxes 15s infinite;
				-webkit-animation: littleBoxes 15s infinite;
				animation-delay: 3s;
			}
			.item2  .animate{
				animation: littleBoxes 12s infinite;
				-webkit-animation: littleBoxes 12s infinite;
				animation-delay: 5s;
			}
			.item3  .animate{
				animation: littleBoxes 8s infinite;
				-webkit-animation: littleBoxes 8s infinite;
				animation-delay: 8s;
			}
			.item4  .animate{
				animation: littleBoxes 10s infinite;
				-webkit-animation: littleBoxes 10s infinite;
				animation-delay: 10s;
			}
		}
	}

	/*------------------------------------*\
	    $ANIMATIONS
	\*------------------------------------*/

	@-webkit-keyframes littleBoxes {
		0% {
			opacity: 0;
		}
		//25%{
		//	width: 100px;
		//	height: 120px;
		//	background-size: 95px 115px;
		//	top: 55%;
		//	margin: -4.7em 0 0 0;
		//	//-webkit-transform: rotate(-90deg); 
		//	
		//}
		50%{
			//-webkit-transform: rotate(-180deg);
			//top: 55%;
			//width: 120px;
			//height: 170px;
			//background-size: 115px 165px;
			//margin-top: -10em;
			border-radius: 0em;
			opacity:1;
		}
		60% {
			opacity:1;
		}
		80% {
			opacity: 1;
		}
		90% {
			opacity: 1;
		}
		//75%{
		//	top: 55%;
		//	width: 120px;
		//	height: 170px;
		//	background-size: 115px 165px;
		//	margin: -4.7em -9.7em 0 0;
		//	opacity:1;
		//	-webkit-transform: rotate(-360deg);
		//}
		100%{	
			opacity:0;

		}
	}

	.slider {
		height: 100%;
		width:100%;
		display:block;
		h1 {
			font-size: @font-size-h2;
			text-transform: uppercase;
		}
		@media (max-width: @screen-xs-max) { 
			h1 {
				font-size: @font-size-h4;
			}
		}
	}

	.styledCheckbox {
		input {
			width:0px;
			height: 0px;
			visibility: hidden;
			opacity: 0;
			position: absolute;
			top:0px;
			left:0px;
		}
		.s_checkbox {
			background-image:url('../images/icons/checkbox.svg');
			background-repeat: no-repeat;
			background-position: center center;
			width: 30px;
			height: 30px;
			display:inline-block;
			vertical-align: middle;
		}

		input:checked + .s_checkbox {
			background-image: url('../images/icons/checkbox_checked.svg');
		}
	}

	.tariffs {
		.margin-top(20px);
		.tariff {
			text-align: center;
			&__image {

			}
			&__name {
				h3 {
					font-size: @font-size-h4;
					font-weight: 800;
					color: @brand-primary;
				}
			}
			&__price {
				span {
					color: #848484;
					font-size: (@font-size-h4 - 3px);
				}
				margin-bottom:10px;
			}
		}
		@media (max-width: @screen-xs-max) { 
			.tariff {
				border-bottom:1px solid #d4d4d4;
				padding-bottom:20px;
				.margin-bottom(20px);
			}
		}
	}

	.additionals {
		.margin-top(20px);
		.additional {
			&__name {
				label {
					font-size: @font-size-h4;
					font-weight: 800;
					color: @brand-primary;
				}
			}

			&__price {
				text-align: right;
				span {
					color: #848484;
					font-size: (@font-size-h4 - 3px);
				}
				@media (max-width: @screen-xs-max) { 
					text-align: center;
				}
			}
		}
	}

	.configurationSummary {
		padding-top: 50px;
		padding-bottom:100px;
		&__inner {
			&__items {
				.name {
					font-size: @font-size-h4;
					color: @brand-primary;
					font-weight: bold;
				}
				.price {
					text-align: right;
					font-size: @font-size-h4;
					font-weight: bold;
					color: silver;
				}
				@media (max-width: @screen-xs-max) { 
					.name {
						font-size: (@font-size-h4 - 10px);
					}
					.price {
						font-size: (@font-size-h4 - 10px);
					}
				}
			}
			&__item {
				padding:10px 0px;
				border-bottom:1px solid #dfdfdf;
			}
			&__add {
				.name {
					font-size: @font-size-h5;
					color: silver;
					font-weight: bold;
				}
				.price {
					font-size: @font-size-h5;
					color: silver;
					font-weight: bold;
				}
				@media (max-width: @screen-xs-max) { 
					.name {
						font-size: (@font-size-h5 - 5px);
					}
					.price {
						font-size: (@font-size-h5 - 5px);
					}
				}
			}
			&__summary {
				margin-top:20px;
				.total {
					font-weight: bold;
					color: @brand-primary;
					.title {
						font-size: @font-size-h4;
						text-align: right;
					}
					.price {
						font-size: @font-size-h4;
						text-align: right;
					}
					@media (max-width: @screen-xs-max) { 
						.title {
							text-align:left;
							font-size: (@font-size-h4 - 5px);
						}
						.price {
							font-size: (@font-size-h4 - 5px);
						}
					}
				}
				.discount {
					font-weight: bold;
					color: silver;
					.title {
						font-size: @font-size-h4;
						text-align: right;
					}
					.price {
						font-size: @font-size-h4;
						text-align: right;
					}
					@media (max-width: @screen-xs-max) { 
						.title {
							text-align:left;
							font-size: (@font-size-h4 - 5px);
						}
						.price {
							font-size: (@font-size-h4 - 5px);
						}
					}
				}
			}
			&__total {
				font-weight: bold;
				color: @brand-primary;
				.title{
					text-align: right;
					font-size: @font-size-h3;
				}
				.price {
					text-align: right;
					font-size: @font-size-h3;
				}
				@media (max-width: @screen-xs-max) {
					.title{
						text-align: left;
						font-size: (@font-size-h3 - 5px);
					}
					.price {
						text-align: right;
						font-size: (@font-size-h3 - 5px);
					}
				}
			}
			&__actions {
				margin-top:30px;
				text-align: center;
			}
		}
	}

	.flyingConfiguration {
		position: fixed;
		z-index:200;
		bottom:0;
		right: 0px;
		@media (max-width: @screen-xs-max) { 
			left: 0px;
		}

		&__box {
			width: 300px;
			@media (max-width: @screen-xs-max) { 
				width: 100%;
			}
		}

		.toggling {
			color: #fff;
			font-size: (@font-size-base + 5px);
		}

		&__toggling {
			width: 50px;
			background-color: @brand-primary;
			padding:5px 10px;
			border-top-left-radius: 16px;
			border-top-right-radius: 16px;
			text-align: center;
		}

		&__header {
			padding:5px 10px;
			text-align: center;
			color: #fff;
			font-weight: bold;
			background-color: @brand-primary;
		}
		&__content {
			background-color: #fff;
			&__inner {
				padding: 10px;
				.right {
					text-align: right;
				}
				&__items {
					&__item {
						font-weight: bold;
						color: @brand-primary;
						.price {
							color: silver;
							padding-top:5px;
						}
					}
					&__add {
						font-weight: bold;
						color: silver;
						.price {
							color: silver;
						}
					}
				}
				&__summary {
					font-weight: bold;
					.price {
						color: @brand-primary;
					}
					.discount {
						color: silver;
					}
					.total {
						color: @brand-primary;
						margin-top:5px;
						font-size: (@font-size-base + 5px);
					}
				}
			}
		}
	}

	.text {
		&__inner {
			h1,h2,h3,h4,h5,strong,b {
				color: @brand-primary;
			}
			p {
				font-size: (@font-size-base + 2px);
			}
			padding:50px 90px;
		}
		&__header {
			height: 350px;
			position: relative;
			&__image {
				position: absolute;
				top:0px;
				left:0px;
				bottom:0px;
				right: 0px;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
			}
			&__title {
				height: 100%;
				width:100%;
				text-align: center;
				&__inner {
					margin:0px auto;
					padding: 15px;
					text-align: center;
					h1,h2,h3,h4,h5 {
						font-size: (@font-size-h1 + 10px);
					}
				}
			}
		}
	}

	.examples {
		margin-top: 20px;
		margin-bottom: 20px;
		.examplesItem {
			padding-bottom:40px;
			border-bottom: 1px solid silver;
			margin-bottom:40px;
			display:block;
			&__content{
				&__inner {

				}

				&__text {
					font-size: (@font-size-base + 2px);
					text-align: justify;
					a {
						text-decoration: underline;
					}
				}

				&__tip {
					background-color: #efefef;
					border-radius: 6px;
					padding:10px;
					color: darken(silver, 10%);
					font-size: (@font-size-base - 0px);

					p {
						margin:0px;
					}
				}
				&__prices {
					margin-top: 25px;
					margin-bottom: 20px;
					color: @brand-primary;
					font-size: (@font-size-base + 6px);
					.price {
						display:block;
					}
					.discount {
						color: #656565;
					}
					p {
						color: @text-color;
					}
				}
			}
		}
	}

	.news {
		min-height:130px;
		&__items {
			margin-top: 60px;
		}
		&__item {
			margin-bottom: 60px;
			&--date {
				//text-align: center;
				color: #d8d7d7;
				font-weight: bold;
				font-size: 20px;
				text-align: right;
				.days {
					padding:0px;
					margin:0px;
					font-size: 80px;
					display:block;
					line-height:80px;
				}
				.year {
					padding:0px;
					margin:0px;
					line-height: 30px;
					display:block;
					font-size: 30px;
				}
			}
			&--text {
				font-size: (@font-size-base + 2px);
				font-weight: bold;
			}
		}
		&__notification {
			display:block;
			text-align: center;
			color: silver;
			font-size: (@font-size-base - 2px);
			font-style: italic;
		}
	}

	.files{
		&__title {
			font-weight: bold;
			font-size: (@font-size-base + 2px);
			color: @brand-primary;
		}
		&__list {
			list-style:none;
			margin:0px;
			padding:0px;
		}
	}

}

.margin-top(@margin){
	margin-top: @margin;
}

.margin-bottom(@margin){
	margin-bottom: @margin;
}

#message {
	position: fixed;
	top:0px;
	left:0px;
	right:0px;
	z-index:9090;
}

p.justify {
	text-align: justify;
}

p.padd20 {
	padding-bottom: 20px;
}

span.halfLine {
	display:block;
	height:7px;
}

.no-margin{
	margin:0px;
}

.no-margin-top {
	.margin-top(0);
}

.no-margin-bottom {
	.margin-bottom(0);
}