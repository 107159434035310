/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700,800|Oswald:400,500,700&subset=latin-ext');

// Core variables and mixins
@import "variables.senior.less";
@import "commons.less";

.serviceAbout {
	h2 {
		font-size: (@font-size-base + 4px);
		color: @brand-primary;
	}
	ul {
		li {
			strong {
				font-size: (@font-size-base + 2px);
				color: @brand-primary;
			}
		}
	}
}

	.easyNavi {
		&__item {
			@media (max-width: @screen-xs-max) { 
				margin-bottom:15px;
				&__inner {
					h3  {
						font-size: (@font-size-base) !important;
					}
				}
			}
		}
	}